.outer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  color: #333;
}

.col {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .sub {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    a {
      text-decoration: none;
      color: #333;
      transition: 300ms ease all;

      &:hover {
        color: lighten(#333, 40%);
      }
    }
  }
}

.orgNumber {
  display: flex;
  align-items: center;
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;

  a {
    text-decoration: none;
    color: #333;
  }
}

.insta {
  transition: 300ms ease all;
  &:hover {
    color: #df0041;
    cursor: pointer;
  }
}

.facebook {
  transition: 300ms ease all;
  &:hover {
    color: #3b5998;
    cursor: pointer;
  }
}
