@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow'), local('Barlow-Regular'),
    url(./assets/fonts/Barlow-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Light'), local('Barlow-Light'),
    url(./assets/fonts/Barlow-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Bold'), local('Barlow-Bold'),
    url(./assets/fonts/Barlow-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 900;
  src: local('Barlow Black'), local('Barlow-Black'),
    url(./assets/fonts/Barlow-Black.ttf) format('truetype');
}

html {
  scroll-behavior: smooth;
}

body,
#root {
  margin: 0;
  font-family: 'Barlow', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
