@import '../styles.scss';

.sub {
  margin-bottom: 20px;
}

.link {
  font-weight: 300;
  color: #333;
  text-decoration: none;
  transition: 300ms ease all;

  &:hover {
    color: lighten(#333, 20%);
  }
}
