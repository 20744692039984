.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin: 2.5rem auto 3.5rem auto;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 260px;
  background: #fff;

  &:before {
    content: '';
    position: absolute;
    top: -60px;
    height: 60px;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom right, transparent 49%, #fff 50%);
  }

  .footerContainer {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 100%;
    margin: 0 auto;
  }
}
