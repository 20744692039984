.container {
  display: flex;
  flex-direction: column;
  height: 600px;
  background-image: url('../../assets/img/slider1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: relative;

    .overlayContentContainer {
      display: flex;
      width: 80%;
      height: 89%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      .overlayContent {
        width: 520px;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 42px;
          font-weight: 900;
        }

        .sub {
          margin-top: 25px;
          font-size: 18px;
          font-weight: 400;
        }

        .button {
          display: flex;
          justify-content: center;
          border-radius: 3px;
          margin: 35px 0;
          background: #5d7365;
          color: #fff;
          padding: 10px;
          font-size: 18px;
          font-weight: 900;
          text-transform: uppercase;
          transition: 300ms ease all;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            background: darken(#5d7365, 10%);
          }
        }
      }
    }
  }

  .inner {
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: row;
    align-items: center;
    // width: 80%;
    margin: 0 auto;

    .navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      // padding-top: 1.5rem;
      margin: 0 auto;
      width: 80%;
      justify-content: space-between;

      
    .logo {
      font-size: 36px;
      font-weight: 900;
      text-transform: uppercase;
      transition: 300ms ease all;
      text-decoration: none;
      color: #fff;
      padding: 0.8rem;

      &:hover {
        cursor: pointer;
        color: darken(#fff, 5%);
      }
    }
    }

    .linksContainer {
      display: flex;
      flex-direction: row;

      .link {
        font-weight: 300;
        padding: 0 10px;
        color: #333;
        text-decoration: none;
        transition: 300ms ease all;
        display: flex;
        align-items: center;

        &:hover {
          color: darken(#333, 20%);
        }
      }
    }
  }
}

.popoverLink {
  font-weight: 300;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  transition: 300ms ease all;
  display: flex;
  align-items: center;

  &:hover {
    color: darken(#333, 20%);
  }
}
