.container {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 42px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .sub {
    font-size: 22px;
    color: #333;
  }

  p {
    color: #333;
  }
}
